import { Button, Grid } from "@mui/material";
import { event, fieldTouched, gtm } from "@racwa/analytics";
import {
  CheckboxInput,
  FreeTextInput,
  SuburbInput,
  ToggleButtonGroup,
  YesNoImNotSure,
  trackCustomFormotivInput,
  useFormotiv,
} from "raci-react-library";
import { FormProvider, useWatch } from "react-hook-form";
import PageTitle from "../../shared/components/PageTitle";
import { FORMOTIV_CONFIG } from "../../shared/constants";
import { AboutYourCarQuestions, aboutYourCarPageTitle } from "./constants";
import { AboutYourCarProps } from "./types";

const {
  damageDescription: aboutYourCarQuestion,
  carIsDriveable: carIsDriveableQuestion,
  findRepairerOutsideWA: findRepairerOutsideWAQuestion,
  preferredSuburbForRepairs: preferredSuburbForRepairsQuestion,
  carWasTowed: carWasTowedQuestion,
} = AboutYourCarQuestions;

export const AboutYourCarForm = ({ form, onSubmit, suburbs }: AboutYourCarProps) => {
  const {
    handleSubmit,
    control,
    clearErrors,
    setValue,
    unregister,
    formState: { isSubmitting },
  } = form;
  const carWasTowed = useWatch({ control, name: "carWasTowed" });
  const carIsDriveable = useWatch({ control, name: "carIsDriveable" });
  const preferredSuburbForRepairs = useWatch({ control, name: "preferredSuburbForRepairs" });
  const findRepairerOutsideWA = useWatch({ control, name: "findRepairerOutsideWA" });

  const isCarNotTowed = carWasTowed === YesNoImNotSure.No;
  const isCarDrivable = carIsDriveable === YesNoImNotSure.Yes;
  const { formotivOnSubmitWrapper } = useFormotiv(FORMOTIV_CONFIG);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(formotivOnSubmitWrapper(onSubmit))} action="#">
        <Grid container spacing={1}>
          <Grid item xs={12} sx={{ marginBottom: "8px" }}>
            <PageTitle id="about-your-car-header" title={aboutYourCarPageTitle} showClaimNumber />
          </Grid>
          <Grid item xs={12}>
            <FreeTextInput
              id={aboutYourCarQuestion.id}
              name={aboutYourCarQuestion.name}
              label={aboutYourCarQuestion.label}
              sublabel={aboutYourCarQuestion.subLabel}
              placeholder="Describe the damage"
              rows={4}
              maxLength={2000}
              errorMessage="Please describe the damage to your car"
            />
          </Grid>
          <Grid item xs={12}>
            <ToggleButtonGroup
              id={carWasTowedQuestion.id}
              name={carWasTowedQuestion.name}
              label={carWasTowedQuestion.label}
              options={YesNoImNotSure}
              errorMessage={"Please select an option"}
              onChange={(_, value) => {
                trackCustomFormotivInput(carWasTowedQuestion.name, "button", value);
                // manually unregister hidden fields to prevent rendering with default values when next visible
                if (value !== YesNoImNotSure.No) {
                  unregister("carIsDriveable");
                  unregister("preferredSuburbForRepairs");
                  unregister("findRepairerOutsideWA");
                }
                gtm(fieldTouched("Was your car towed"));
                gtm(event(`Was your car towed - ${value}`));
              }}
            />
          </Grid>
          {isCarNotTowed && (
            <>
              <Grid item xs={12}>
                <ToggleButtonGroup
                  id={carIsDriveableQuestion.id}
                  name={carIsDriveableQuestion.name}
                  label={carIsDriveableQuestion.label}
                  options={YesNoImNotSure}
                  errorMessage="Please select an option"
                  onChange={(_, value) => {
                    trackCustomFormotivInput(carIsDriveableQuestion.name, "button", value);
                    // manually unregister hidden fields to prevent rendering with default values when next visible
                    if (value !== YesNoImNotSure.Yes) {
                      unregister("preferredSuburbForRepairs");
                      unregister("findRepairerOutsideWA");
                    }
                    gtm(fieldTouched("Can your car be safely driven to the repairer"));
                    gtm(event(`Can your car be safely driven to the repairer - ${value}`));
                  }}
                />
              </Grid>
              {isCarDrivable && (
                <>
                  <Grid item xs={12}>
                    <SuburbInput
                      name={preferredSuburbForRepairsQuestion.name}
                      label={preferredSuburbForRepairsQuestion.label}
                      sublabel={preferredSuburbForRepairsQuestion.subLabel}
                      options={suburbs}
                      placeholder="Suburb or postcode"
                      errorMessage="Please enter a preferred area"
                      disabled={findRepairerOutsideWA}
                      required={!findRepairerOutsideWA}
                      onChange={(v) => {
                        trackCustomFormotivInput(preferredSuburbForRepairsQuestion.name, "text", v);
                        clearErrors("findRepairerOutsideWA");
                      }}
                    />

                    <CheckboxInput
                      id={findRepairerOutsideWAQuestion.id}
                      name={findRepairerOutsideWAQuestion.name}
                      label={findRepairerOutsideWAQuestion.label}
                      required={!preferredSuburbForRepairs}
                      onChange={(_, checked) => {
                        trackCustomFormotivInput(findRepairerOutsideWAQuestion.name, "checkbox", checked);
                        if (checked) {
                          setValue("preferredSuburbForRepairs", { cityName: "" });
                          clearErrors("preferredSuburbForRepairs");
                        }
                        gtm(fieldTouched(findRepairerOutsideWAQuestion.label));
                      }}
                    />
                  </Grid>
                </>
              )}
            </>
          )}
          <Grid item xs={12}>
            <Button
              type="submit"
              id="submit"
              data-testid="submit"
              color="primary"
              variant="contained"
              fullWidth
              sx={{ margin: "36px 0px 16px 0px" }}
              disabled={isSubmitting}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default AboutYourCarForm;
