import { Button, Grid } from "@mui/material";
import { event, fieldTouched, gtm } from "@racwa/analytics";
import {
  FreeTextInput,
  OptionalText,
  RequiredOption,
  ToggleButtonGroup,
  ToggleButtonOption,
  YesNoUnknown,
  trackCustomFormotivInput,
  useFormotiv,
} from "raci-react-library";
import { FormProvider, useWatch } from "react-hook-form";
import PageTitle from "../../shared/components/PageTitle";
import { FORMOTIV_CONFIG, HIDE_LICENSE_QUESTION } from "../../shared/constants";
import {
  DriverHistoryQuestions,
  HeldValidLicenceGtmDescription,
  LicenceSuspendedGtmDescription,
  UnderTheInfluenceGtmDescription,
  driverHistoryPageTitle,
} from "./constants";
import useDriverHistoryTooltips from "./hooks/useDriverHistoryTooltips";
import { DriverHistoryFormProps } from "./types";

const titleId = "your-driver-history-header";
const nextButtonId = "submit-button";
const toggle = HIDE_LICENSE_QUESTION === "true";
const {
  driverWasUnderTheInfluence,
  driverHasValidLicence,
  driverHasLicenceSuspensionOrCancellation,
  suspensionOrCancellationDetails,
} = DriverHistoryQuestions;

export const DriverHistoryForm = ({ form, driverName, onSubmit }: DriverHistoryFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = form;
  const hasSuspensionOrCancellation = useWatch({ control, name: "driverHasLicenceSuspensionOrCancellation" });
  const {
    driverWasUnderTheInfluenceTooltipProps,
    driverHasValidLicenceTooltipProps,
    driverHasLicenceSuspensionOrCancellationTooltipProps,
  } = useDriverHistoryTooltips();

  const showSuspensionOrCancellationDetails = !driverName && hasSuspensionOrCancellation === YesNoUnknown.Yes;

  const toggleOptionsErrorMessage = driverName ? RequiredOption : "Please select Yes or No";

  const toggleOptions = Object.values(YesNoUnknown)
    .filter((option) => !!driverName || option !== YesNoUnknown.Unknown)
    .map((option) => ({ value: option, text: option }) satisfies ToggleButtonOption);

  const { formotivOnSubmitWrapper } = useFormotiv(FORMOTIV_CONFIG);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(formotivOnSubmitWrapper(onSubmit))} action="#">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <PageTitle id={titleId} title={driverHistoryPageTitle({ driverName })} showClaimNumber />
          </Grid>
          <Grid item xs={12}>
            <ToggleButtonGroup
              id={driverWasUnderTheInfluence.id}
              name={driverWasUnderTheInfluence.name}
              label={driverWasUnderTheInfluence.label({ driverName })}
              options={toggleOptions}
              errorMessage={toggleOptionsErrorMessage}
              tooltipProps={driverWasUnderTheInfluenceTooltipProps}
              onChange={(_, value: ToggleButtonOption["value"] | null | undefined) => {
                trackCustomFormotivInput(driverWasUnderTheInfluence.name, "button", value);
                if (value === null || value === undefined) return;
                gtm(event(`${UnderTheInfluenceGtmDescription} - ${value}`));
                gtm(fieldTouched(UnderTheInfluenceGtmDescription));
              }}
            />
          </Grid>
          {!toggle && (
            <Grid item xs={12}>
              <ToggleButtonGroup
                id={driverHasValidLicence.id}
                name={driverHasValidLicence.name}
                label={driverHasValidLicence.label({ driverName })}
                options={toggleOptions}
                errorMessage={toggleOptionsErrorMessage}
                tooltipProps={driverHasValidLicenceTooltipProps}
                onChange={(_, value: ToggleButtonOption["value"] | null | undefined) => {
                  trackCustomFormotivInput(driverHasValidLicence.name, "button", value);
                  if (value === null || value === undefined) return;
                  gtm(event(`${HeldValidLicenceGtmDescription} - ${value}`));
                  gtm(fieldTouched(HeldValidLicenceGtmDescription));
                }}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <ToggleButtonGroup
              id={driverHasLicenceSuspensionOrCancellation.id}
              name={driverHasLicenceSuspensionOrCancellation.name}
              label={driverHasLicenceSuspensionOrCancellation.label({ driverName })}
              options={toggleOptions}
              errorMessage={toggleOptionsErrorMessage}
              tooltipProps={driverHasLicenceSuspensionOrCancellationTooltipProps}
              onChange={(_, value: ToggleButtonOption["value"] | null | undefined) => {
                trackCustomFormotivInput(driverHasLicenceSuspensionOrCancellation.name, "button", value);
                if (value === null || value === undefined) return;
                gtm(event(`${LicenceSuspendedGtmDescription} - ${value}`));
                gtm(fieldTouched(LicenceSuspendedGtmDescription));
              }}
            />
          </Grid>

          {showSuspensionOrCancellationDetails && (
            <Grid item xs={12}>
              <FreeTextInput
                id={suspensionOrCancellationDetails.id}
                name={suspensionOrCancellationDetails.name}
                label={suspensionOrCancellationDetails.label}
                sublabel={DriverHistoryQuestions.suspensionOrCancellationDetails.subLabel}
                optionalText={OptionalText.IfKnown}
                placeholder="Provide details"
                rows={4}
                maxLength={255}
                required={false}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              type="submit"
              id={nextButtonId}
              data-testid={nextButtonId}
              color="primary"
              variant="contained"
              fullWidth
              sx={{ margin: "28px 0px 16px 0px" }}
              disabled={isSubmitting}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default DriverHistoryForm;
